import FilterableFileList from "../sitepackage/filterableFileList/FilterableFileList";
import HashArgumentHelper from "../sitepackage/filterableFileList/HashArgumentHelper";
import HashArgumentObserver from "../sitepackage/filterableFileList/HashArgumentObserver";
import { onDomReady } from "../utils/on-dom-ready";

const filterTypes = {
  CATEGORY: "category",
};

onDomReady(() => {
  const fileListContainer = document.querySelector(
    ".tx_sitepackage_news_fileListContainer--webinars"
  );
  if (
    fileListContainer instanceof HTMLElement &&
    !fileListContainer.dataset.filelistinitialized
  ) {
    const fileList = new FilterableFileList(fileListContainer, {
      fileListSelector: ".tx_sitepackage_news-fileList",
      filterSets: [
        {
          container: ".tx_sitepackage_news_categoryContainer",
          type: "category",
        },
      ],
      itemsSelector: ".tx_sitepackage_news--item",
      isotope: {
        transitionDuration: "0.2s",
      },
    });
    const hashArgumentHelper = new HashArgumentHelper(
      window.location,
      filterTypes.CATEGORY
    );

    const hashArgumentObserver = new HashArgumentObserver(hashArgumentHelper);

    fileList.init(hashArgumentObserver).then(() => {
      const categoryFilter = hashArgumentHelper.getFilter(filterTypes.CATEGORY);
      if (categoryFilter !== null) {
        categoryFilter.forEach((filter) => {
          fileList.setFilterState(filterTypes.CATEGORY, filter, true, true);
        });
      }
    });
  }
});
