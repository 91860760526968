import FilterableFileList from "../sitepackage/filterableFileList/FilterableFileList";
import HashArgumentHelper from "../sitepackage/filterableFileList/HashArgumentHelper";
import HashArgumentObserver from "../sitepackage/filterableFileList/HashArgumentObserver";
import { onDomReady } from "../utils/on-dom-ready";

const filterTypes = {
  CATEGORY: "category",
  TAG: "tag",
};

onDomReady(() => {
  const fileListContainer = document.querySelector(
    ".tx_sitepackage_news_fileListContainer--mediathek"
  );
  if (
    fileListContainer instanceof HTMLElement &&
    !fileListContainer.dataset.filelistinitialized
  ) {
    const fileList = new FilterableFileList(fileListContainer, {
      fileListSelector: ".tx_sitepackage_news-fileList",
      filterSets: [
        {
          container: ".tx_sitepackage_news_categoryContainer",
          type: filterTypes.CATEGORY,
        },
        {
          container: ".tx_sitepackage_news_tagContainer",
          type: filterTypes.TAG,
        },
      ],
      itemsSelector: ".tx_sitepackage_news--item",
      isotope: {
        layoutMode: "fitRows",
        getSortData: {
          new: "[data-new]",
          title: ".card__text",
        },
        sortBy: "new",
      },
      sortButtonsSelector: ".mediathek--sortButton",
    });

    const hashArgumentHelper = new HashArgumentHelper(
      window.location,
      filterTypes.CATEGORY,
      filterTypes.TAG
    );
    hashArgumentHelper.convertLegacyUrl();

    const hashArgumentObserver = new HashArgumentObserver(hashArgumentHelper);

    fileList.init(hashArgumentObserver).then(() => {
      const categoryFilter = hashArgumentHelper.getFilter(filterTypes.CATEGORY);
      if (categoryFilter !== null) {
        categoryFilter.forEach((filter) => {
          fileList.setFilterState(filterTypes.CATEGORY, filter, true, true);
        });
      }
      const tagFilter = hashArgumentHelper.getFilter(filterTypes.TAG);
      if (tagFilter !== null) {
        tagFilter.forEach((filter) => {
          fileList.setFilterState(filterTypes.TAG, filter, true, true);
        });
      }
    });
  }
});
